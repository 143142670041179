import React, { Component } from 'react'
import './Conversor.css'


export default class Conversor extends Component {
    
    constructor () {
        super()

        this.state = {
            moedas: [],
            moedaA: "ALL",
            moedaB: "ALL",
            moedaA_valor: "",
            moedaB_valor: 0,

        }

        this.converter=this.converter.bind(this)
        this.render=this.render.bind(this)
    }
    componentDidMount(){
        let initialMoedas = [];
        const api_key=process.env.REACT_APP_API_KEY
        
        let url=`https://free.currconv.com/api/v7/currencies?apiKey=${api_key}`

    
        fetch(url)
        .then(res=>{
            return res.json()
        })
        .then(json=>{
            //console.log("json output:")
            //console.log(json.results)
            
            //initialMoedas = json.results.map( (moeda)=>{ return moeda});
            Object.keys(json.results).forEach(key => initialMoedas.push( { id:key, currencyName:json.results[key].currencyName } ))

            //console.log(initialMoedas);
            this.setState ({
                moedas: initialMoedas, //['USD', 'BRL', 'JPY'],
            });
        });

    }
    converter(){ 
        const api_key=process.env.REACT_APP_API_KEY
        

        let de_para = `${this.state.moedaA}_${this.state.moedaB}`
        //let url = `http://free.currencyconverterapi.com/api/v5/convert?apiKey=${api_key}&q=${de_para}&compact=y`
        let url = `https://free.currconv.com/api/v7/convert?apiKey=${api_key}&q=${de_para}&compact=y`
        fetch(url)
        .then(res=>{
            return res.json()
        })
        .then(json=>{
            let cotacao = json[de_para].val;
            let moedaB_valor = (parseFloat(this.state.moedaA_valor) * cotacao).toFixed(2)
            this.setState ({moedaB_valor})
        })
        
        //console.log(url)
    }
    render() {
        let moedas = this.state.moedas;
        let optionItems = moedas.map((moeda) =>
                <option key={moeda.id}>{moeda.id}</option>
            );
       return (
            <div className="conversor">
                <h2>
                    <select autoFocus name="moedaA" id="moedaA" onChange={(event)=>{this.setState({moedaA: event.target.value}) }}>
                        {optionItems}
                    </select>
                    to
                    <select name="moedaB" id="moedaB" onChange={(event)=>{this.setState({moedaB: event.target.value})}}>
                        {optionItems}
                    </select>
                </h2>
                
                <input type="number" min="0" placeholder="Value to convert" onChange={(event)=>{ this.setState({ moedaA_valor: event.target.value })}}></input>
                <input type='submit' value="Converter" onClick={ this.converter }  ></input>
                <h2>{this.state.moedaB_valor}</h2>
            </div>
        )
    }
}