import React from 'react';
//import logo from './logo.svg';
import './App.css';
import Conversor from './components/Conversor'

function App() {
  return (
    <div className="App">
      <h1>Conversor de moedas</h1>
      <div className="linha">
        <Conversor></Conversor>
      </div>
      <div className="rodape">
          <p>Data provided by:</p>
          <p><a href="https://www.currencyconverterapi.com/">https://www.currencyconverterapi.com/</a></p>
          <p>Desenvolvido em React e hospedado em Firebase</p>
          <p>Baseado no video: <a href='https://www.youtube.com/watch?v=tbLziJchz48'>Video</a></p>
      </div>
    </div>
  );
}

export default App;
